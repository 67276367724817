import styled, { keyframes } from 'styled-components';
import { rem } from 'polished';
import theme from 'config/theme';

const scaleInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export const PageWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${theme.colors.bgGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  max-width: 960px;
  padding: 20px 0;
  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
  }
`

export const Logo = styled.img`
  max-width: 100%;
  width: auto;
`

export const PageContent = styled.div`
  max-width: 960px;
  width: 100%;
  background-color: ${theme.colors.white};
  padding: 35px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(5, 22, 52, 0.15);
  
  @media (max-width: 1023px) {
		padding: 35px 10px;
	}
`;

export const BigCircle = styled.div`
  width: 150px;
  height: 150px;
  background-color: ${theme.colors.apple};
  border: 25px solid #F1FAED;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 10px auto 35px auto;
  border-radius: 50%;
  animation: ${scaleInOut} 0.4s 0.6s both;
`;

export const MainHeader = styled.h2`
  font-size: ${rem('20px')};
  margin: 15px 0;
  color: ${theme.colors.darkNavy};
`;

export const Paragraph = styled.div`
  font-size: ${rem('12px')};
  color: ${theme.colors.darkNavy};
  margin: 10px 0;
`;

export const Footer = styled.div`
  padding-top: 30px;
  padding-bottom: 5px;
  margin-top: 60px;
  border-top: 1px solid ${theme.colors.gray};
  @media (max-width: ${theme.breakpoints.md}) {
    margin-top: 30px;
  }
`;

export const FooterText = styled.div`
  font-size: ${rem('10px')};
  color: ${theme.colors.textGray};
  text-align: center;
`;

export const TextLink = styled.a`
  color: ${theme.colors.apple};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
