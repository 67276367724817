import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import pl from './locales/pl.json';
import ro from './locales/ro.json';
import en from './locales/en.json';
import nl from './locales/nl.json';
import ua from './locales/ua.json';
import lt from './locales/lt.json';
import lv from './locales/lv.json';
import hu from './locales/hu.json';

const currentLang = JSON.stringify(localStorage.getItem('lang')) || 'pl';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pl: {translation: pl},
      ro: {translation: ro},
      en: {translation: en},
      nl: {translation: nl},
      ua: {translation: ua},
      lt: {translation: lt},
      lv: {translation: lv},
      hu: {translation: hu},
    },
    lng: currentLang,
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;
