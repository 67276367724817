import * as actions from './actions';
import API from 'api';
import {URL_GET_ROUTE} from 'api/config';
import {displayProperToast} from 'utils/toast';
import history from 'config/history';

export const getRoute = (hash, shouldBeLoaderVisible, callback) => {
  return async dispatch => {
    if (shouldBeLoaderVisible) {
      dispatch(actions.setRouteLoading(true));
    }
    try {
      const res = await API.get(URL_GET_ROUTE, {
        params: {
          hash,
        },
      });
      if (res.success) {
        dispatch(actions.setRoute(res.data));
        dispatch(actions.setCanRefresh(res.data.refresh));
        if (res.data && res.data.ended) {
          history.push({
            pathname: '/info',
            content: {
              success: false,
              title: 'Link udostępniania lokalizacji wygasł!',
              desc: 'Udostępniany przejazd zakończył się lub dane zawarte w linku są niepoprawne.'
            },
          });
        }
        if (res.data && !res.data.ended && res.data.started) {
          const currentCar = res.data.transfers[res.data.currentTransfer].car;
          if (!currentCar && currentCar.geo) {
            displayProperToast({
              error: ['Nie udało się pobrać lokalizacji samochodu.']
            });
          }
        }
      } else {
        if (callback) {
          callback();
        }
      }
      if (shouldBeLoaderVisible) {
        dispatch(actions.setRouteLoading(false));
      }
    } catch(err) {
      if (callback) {
        callback();
      }
      console.log(err);
    }
  };
};
