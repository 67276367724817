import React, {useEffect, useState} from 'react';
import {
	PageWrapper,
	PageContent,
	MainHeader,
	Paragraph,
	LogoWrapper,
	Logo,
	BigCircle
} from 'styled/Layout';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import logo from 'assets/images/gtv_logo.svg';
import { URL_ACTIVATE_ACCOUNT } from 'api/config';
import queryString from 'query-string';
import API from 'api';
import PageLoader from 'components/PageLoader';
import ScreenFooter from 'components/ScreenFooter';
import {useTranslation} from 'react-i18next';

const ActivateAccount = ({ location }) => {

	const {t} = useTranslation();
	const [dataLoading, setDataLoading] = useState(true);
	const [activateSuccess, setActivateSucces] = useState(null);
	const content = {
		success: {
			title: t('activate.successHeader'),
			desc: t('activate.successDesc'),
		},
		error: {
			title: t('activate.failureHeader'),
			desc: t('activate.failureDesc'),
		},
	};

	const activate = async () => {
		const search = location.search;
		if (search.length) {
			const query = queryString.parse(location.search);
			if (query && query.hash) {
				try {
					const res = await API.get(URL_ACTIVATE_ACCOUNT, {
						params: {
							hash: query.hash
						}
					});
					setDataLoading(false);
					if (res && res.success) {
						setActivateSucces(true);
					} else {
						setActivateSucces(false);
					}
				} catch(err) {
					setActivateSucces(false);
					setDataLoading(false);
					console.log(err);
				}
			} else {
				setDataLoading(false);
			}
		} else {
			setDataLoading(false);
		}
	};

	useEffect(() => {
		activate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageWrapper>
			{ dataLoading
			? <PageLoader />
			: (
				<>
					<LogoWrapper>
						<Logo src={logo} />
					</LogoWrapper>
					<PageContent>
						<BigCircle>
							{ activateSuccess ? <CheckIcon /> : <CrossIcon /> }
						</BigCircle>
						<MainHeader>{ activateSuccess ? content.success.title : content.error.title }</MainHeader>
						<Paragraph>{ activateSuccess ? content.success.desc : content.error.desc }</Paragraph>
						<ScreenFooter />
					</PageContent>
				</>
			)}
		</PageWrapper>
	);
;}

export default ActivateAccount;
