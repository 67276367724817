import React, {useState} from 'react';
import {
	PageWrapper,
	PageContent,
	MainHeader,
	Paragraph,
	LogoWrapper,
	Logo,
} from 'styled/Layout';
import {
	Form,
	InputBar,
	InputLabel,
	Label,
	Input,
	LoginButton,
	PrimaryButtonIcon,
	PrimaryButtonText,
	ButtonLoader,
	ErrorMessage
} from './styles';
import logo from 'assets/images/gtv_logo.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow.svg';
import queryString from "query-string";
import API from 'api';
import {URL_RESET_PASSWORD} from 'api/config';
import history from 'config/history';
import ScreenFooter from 'components/ScreenFooter';
import {useTranslation} from 'react-i18next';

const NewPassword = ({ location }) => {
	const {t} = useTranslation();
	const [savePass, setSavePass] = useState(false);
	const [error, setError] = useState(null);
	const [form, setForm] = useState({
		password: '',
		passwordRep: ''
	});

	const updateField = e => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const search = location.search;
		if (search.length) {
			const query = queryString.parse(location.search);
			if (query && query.hash) {
				try {
					setSavePass(true);
					const res = await API.post(URL_RESET_PASSWORD, {
						hash: query.hash,
						password: form.password,
						password2: form.passwordRep,
					});
					setSavePass(false);
					if (res && res.data && res.success) {
						setError(null);
						history.push({
							pathname: '/info',
							content: {
								success: true,
								title: t('resetPassword.infoSuccessHeader'),
								desc: t('resetPassword.infoSuccessDesc'),
						 	},
						});
					} else if (res.data && res.data.form_error) {
						setError(res.data.form_error);
					} else {
						history.push({
							pathname: '/info',
							content: {
								success: false,
								title: t('resetPassword.infoFailureHeader'),
								desc: t('resetPassword.infoFailureDesc'),
							},
						});
					}

				} catch(err) {
					setSavePass(false);
					console.log(err);
				}
			} else {
				setSavePass(false);
			}
		} else {
			setSavePass(false);
		}
	};

	return (
		<PageWrapper>
			<LogoWrapper>
				<Logo src={logo} />
			</LogoWrapper>
			<PageContent>
				<MainHeader>{t('resetPassword.formHeader')}</MainHeader>
				<Paragraph>{t('resetPassword.formSubHeader')}</Paragraph>
					{error && <ErrorMessage error>{error}</ErrorMessage>}
				<Form onSubmit={handleSubmit}>
					<InputBar>
						<Input type="password" name="password" onChange={updateField} required />
						<InputLabel />
						<Label htmlFor="password">{t('resetPassword.newPasswordLabel')}</Label>
					</InputBar>
					<InputBar>
						<Input type="password" name="passwordRep" onChange={updateField} required />
						<InputLabel />
						<Label htmlFor="password">{t('resetPassword.confirmNewPasswordLabel')}</Label>
					</InputBar>
					<LoginButton type="submit">
						<PrimaryButtonText>{savePass ? t('resetPassword.savingPassword') : t('resetPassword.savePassword')}</PrimaryButtonText>
						<PrimaryButtonIcon>
							{savePass ? <ButtonLoader /> : <ArrowRight />}
						</PrimaryButtonIcon>
					</LoginButton>
				</Form>
				<ScreenFooter />
			</PageContent>
		</PageWrapper>
	);
};

export default NewPassword;
