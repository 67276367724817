import styled from 'styled-components';
import theme from 'config/theme';

export const MarkerWrapper = styled.div`
  position: relative;
`;

export const PointLabel = styled.div`
  font-size: 11px;
  color: ${theme.colors.white};
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-45%);
`;

export const BusNumberFrom = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${theme.colors.white};
  position: absolute;
  bottom: 7px;
  left: 15px;
`;

export const BusNumberTo = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${theme.colors.white};
  position: absolute;
  bottom: 7px;
  right: 7px;
`;
