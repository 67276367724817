import React from 'react';
import {PulseWrapper, Pulse, CarIconWrapper, SmallCircle} from './styles';
import {OverlayView} from '@react-google-maps/api';
import {ReactComponent as CarIcon} from 'assets/icons/car_marker.svg';

const VehicleMarker = ({ degree, position }) => {
  return (
    <OverlayView 
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}>
      <PulseWrapper degree={degree}>
        <SmallCircle />
        <Pulse />
        <CarIconWrapper>
          <CarIcon />
        </CarIconWrapper>
      </PulseWrapper>
    </OverlayView>
  );
};

export default VehicleMarker;
