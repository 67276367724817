import React from 'react';
import {
	PageWrapper,
	PageContent,
	MainHeader,
	Paragraph,
	LogoWrapper,
	Logo,
	BigCircle
} from 'styled/Layout';
import logo from 'assets/images/gtv_logo.svg';
import {ReactComponent as StarsIcon} from 'assets/icons/stars.svg';
import {ReactComponent as CrossIcon} from 'assets/icons/cross.svg';
import history from 'config/history';
import ScreenFooter from 'components/ScreenFooter';

const Info = ({ location }) => {
	
	if (!location.content) {
		history.push('/not-found');
	}

	const {content} = location;

	return (
		<PageWrapper>
			<LogoWrapper>
				<Logo src={logo} />
			</LogoWrapper>
			<PageContent>
				<BigCircle>
					{content && content.success ? <StarsIcon /> : <CrossIcon />}
				</BigCircle>
				<MainHeader>{content && content.title}</MainHeader>
				<Paragraph>{content && content.desc}</Paragraph>
				<ScreenFooter />
			</PageContent>
		</PageWrapper>
	);
};

export default Info;
