import React from 'react';
import {OverlayView} from '@react-google-maps/api';
import {MarkerWrapper, PointLabel, BusNumber} from './styles';
import {ReactComponent as StartIcon} from 'assets/icons/start_marker.svg';
import {useTranslation} from 'react-i18next';

const StartMarker = ({ position, busNumber }) => {

  const {t} = useTranslation();

  return (
    <OverlayView 
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}>
      <MarkerWrapper>
        <StartIcon />
        <PointLabel>{t('markers.start')}</PointLabel>
        <BusNumber>{busNumber}</BusNumber>
      </MarkerWrapper>
    </OverlayView>
  );
};

export default StartMarker;
