import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import routeReducer from 'store/route/reducer';

export const configureStore = (initialState = {}) => {
  return createStore(
    combineReducers({
      route: routeReducer,
    }),
    initialState,
    applyMiddleware(thunk)
  );
};
