import * as types from './types';

const initialState = {
  currentRoute: null,
  canRefresh: true,
  routeLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
      };
    case types.SET_CAN_REFRESH:
      return {
        ...state,
        canRefresh: action.payload,
      };
    case types.SET_ROUTE_LOADING:
      return {
        ...state,
        routeLoading: action.payload,
      }
    default:
      return state;
  }
};

