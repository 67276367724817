import styled from 'styled-components';
import theme from 'config/theme';

export const MarkerWrapper = styled.div`
  position: relative;
`;

export const PointLabel = styled.div`
  font-size: 11px;
  color: ${theme.colors.white};
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-50%);
`;

export const BusNumber = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${theme.colors.white};
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translate(-50%);
`;
