import styled from 'styled-components';

export const MapWrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

export const MapLogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 20px;
  max-width: 150px;
`;