import React, {useEffect} from 'react';
import {GlobalStyles} from 'globalStyles';
import { Router, Switch, Route, Redirect } from 'react-router';
import history from 'config/history';
import ActivateAccount from 'components/ActivateAccount';
import NotFound from 'components/NotFound';
import Map from 'components/Map';
import NewPassword from 'components/NewPassword';
import Info from 'components/Info';
import {ToastContainer} from 'react-toastify';
import queryString from 'query-string';
import i18next from 'i18next';

function App() {

  useEffect(() => {
    const currentLang = localStorage.getItem('lang') || 'en';

    if (window.location.pathname === '/map') {
      const browserLang = window.navigator.userLanguage || window.navigator.language;
      localStorage.setItem('lang', browserLang);
      i18next.changeLanguage(browserLang);
    } else {
      const query = queryString.parse(window.location.search);
      const lang = query && query.lang ? query.lang : currentLang;
      localStorage.setItem('lang', lang);
  
      i18next.changeLanguage(lang);
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <ToastContainer className="ISSToast" />
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/activate"
            component={ActivateAccount} />
          <Route 
            exact 
            path="/map"
            component={Map} />
          <Route
            exact
            path="/not-found"
            component={NotFound} />
          <Route
            exact
            path="/reset-password"
            component={NewPassword} />
          <Route
            exact
            path="/info"
            component={Info} />
          <Redirect from="/*" to="/not-found" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
