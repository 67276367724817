import styled from 'styled-components';
import theme from 'config/theme';

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircleWrapper = styled.div`
  margin: 0 25px;
`;


export const FourNumber = styled.div`
  font-size: 140px;
  font-weight: bold;
  color: ${theme.colors.apple};
  @media (max-width: ${theme.breakpoints.md}) {
    font-size: 100px;
  }
  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: 80px;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    display: none;
  }
`;
