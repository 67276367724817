import React, {Fragment, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MapWrapper, MapLogoWrapper} from './styles';
import VehicleMarker from 'components/VehicleMarker';
import StartMarker from 'components/StartMarker';
import FinishMarker from 'components/FinishMarker';
import TransferMarker from 'components/TransferMarker';
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import {setRoute, setCanRefresh} from 'store/route/actions';
import {getRoute} from 'store/route/thunks';
import queryString from 'query-string';
import shortid from 'shortid';
import {PageWrapper} from 'styled/Layout';
import PageLoader from 'components/PageLoader';
import history from 'config/history';
import {
  Logo,
} from 'styled/Layout';
import logo from 'assets/images/gtv_logo.svg';
import {useTranslation} from 'react-i18next';

const Map = ({ location }) => {

  const [map, setMap] = useState(null);
  const [boundingFitted, setBoundingFitted] = useState(false);
  const currentRoute = useSelector(({route}) => route.currentRoute);
  const canRefresh = useSelector(({route}) => route.canRefresh);
  const routeLoading = useSelector(({route}) => route.routeLoading);

  const {t} = useTranslation();
  const d = useDispatch();

  useEffect(() => {
    d(setCanRefresh(true));

    return () => {
      d(setRoute(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let carsPostionsInterval;

    const getRouteFunctionality = () => {
      const query = queryString.parse(location.search);
      if (query && query.hash) {
        d(getRoute(query.hash, true, () => {
          history.push({
            pathname: '/info',
            content: {
              success: false,
              title: t('share.failureHeader'),
              desc: t('share.failureDesc'),
            },
          });
        }));
        clearInterval(carsPostionsInterval);
        carsPostionsInterval = setInterval(() => {
          if (canRefresh) {
            d(getRoute(query.hash, false));
          } else {
            clearInterval(carsPostionsInterval);
            return;
          }
        }, 60000);
      } else {
        history.push({
          pathname: '/info',
          content: {
            success: false,
            title: t('share.failureHeader'),
            desc: t('share.failureDesc'),
          },
        });
      }
    }

    if (canRefresh) {
      getRouteFunctionality();
    }

    return () => {
      clearInterval(carsPostionsInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRefresh]);

  useEffect(() => {
    if (currentRoute && map && boundingFitted === false) {
      const latlngbounds = new window.google.maps.LatLngBounds();
      for (var i = 0; i < currentRoute.bounding.length; i++) {
        const newLatLng = new window.google.maps.LatLng(currentRoute.bounding[i].latitude, currentRoute.bounding[i].longitude);
        latlngbounds.extend(newLatLng);
      }
      map.fitBounds(latlngbounds);
      setBoundingFitted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute, map]);

  const renderTransfers = () => {
    if (currentRoute && currentRoute.transfers) {
      return currentRoute.transfers.map((transfer, i) => {
        if (i === 0) {
          return (
            <Fragment key={shortid.generate()}>
              <StartMarker busNumber={transfer.car.number} position={{lat: transfer.begin.geo_lat, lng: transfer.begin.geo_lon}} />
              {currentRoute.transfers.length !== 1
                ?
                <TransferMarker
                  busFromNumber={transfer.car.number}
                  busToNumber={currentRoute.transfers[i + 1] ? currentRoute.transfers[i + 1].car.number : '-'}
                  position={{lat: transfer.end.geo_lat, lng: transfer.end.geo_lon}} />
                :
                <FinishMarker
                  busNumber={currentRoute.transfers[i] ? currentRoute.transfers[i].car.number : '-'}
                  position={{lat: transfer.end.geo_lat, lng: transfer.end.geo_lon}} />
                }
            </Fragment>
          );
        } else if (i === currentRoute.transfers.length - 1) {
          return (
            <FinishMarker
              key={shortid.generate()}
              busNumber={transfer.car.number}
              position={{lat: transfer.end.geo_lat, lng: transfer.end.geo_lon}} />
          )
        } else {
          return (
            <TransferMarker
              key={shortid.generate()}
              busFromNumber={transfer.car.number}
              busToNumber={currentRoute.transfers[i + 1] ? currentRoute.transfers[i + 1].car.number : '-'}
              position={{lat: transfer.end.geo_lat, lng: transfer.end.geo_lon}} />
          );
        }
      });
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  let vehicleCoordinates = null;
  if (currentRoute && currentRoute.transfers && currentRoute.currentTransfer !== null) {
    const currentTransferingCar = currentRoute.transfers[currentRoute.currentTransfer].car;
    if (currentTransferingCar.geo) {
      vehicleCoordinates = {
        latitude: currentTransferingCar.geo_lat,
        longitude: currentTransferingCar.geo_lon,
        degree: currentTransferingCar.geo_degree
      };
    }
  }

  return (
    <MapWrapper>
      <MapLogoWrapper>
        <Logo src={logo} />
      </MapLogoWrapper>
      <LoadScript
        googleMapsApiKey="AIzaSyCo-EKioptiMxg0ydehlX5oNsQoEsnQ4YQ">
        {routeLoading
        ? (
          <PageWrapper>
            <PageLoader />
          </PageWrapper>
        ) : (
        <GoogleMap
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
          mapContainerStyle={{height: '100%', width: '100%', position: 'relative'}}
          center={{lat: 51.962575, lng: 19.574748}}
          zoom={5}
          onLoad={onLoad}
          onUnmount={onUnmount}>
          <>
            {renderTransfers()}
            {vehicleCoordinates &&
            <VehicleMarker
              degree={vehicleCoordinates.degree}
              position={{lat: vehicleCoordinates.latitude, lng: vehicleCoordinates.longitude}} />
            }
          </>
        </GoogleMap>
        )}
      </LoadScript>
    </MapWrapper>
  );
};

export default Map;
