import React from 'react';
import { 
	PageWrapper,
	PageContent,
	MainHeader,
	Paragraph,
	LogoWrapper,
  Logo,
  BigCircle
} from 'styled/Layout';
import { NotFoundWrapper, CircleWrapper, FourNumber } from './styles';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import logo from 'assets/images/gtv_logo.svg';
import ScreenFooter from 'components/ScreenFooter';
import {useTranslation} from 'react-i18next';

const NotFound = () => {

	const {t} = useTranslation();

	return (
		<PageWrapper>
			<LogoWrapper>
				<Logo src={logo} />
			</LogoWrapper>
			<PageContent>
        <NotFoundWrapper>
          <FourNumber>4</FourNumber>
          <CircleWrapper>
            <BigCircle>
              <CrossIcon />
            </BigCircle>
          </CircleWrapper>
          <FourNumber>4</FourNumber>
        </NotFoundWrapper>
				<MainHeader>{t('notFound.header')}</MainHeader>
				<Paragraph>{t('notFound.desc')}</Paragraph>
				<ScreenFooter />
			</PageContent>
		</PageWrapper>
	);
;}

export default NotFound;
