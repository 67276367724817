const colors = {
	white: '#ffffff',
	black: '#000000',
	apple: '#66cb35',
  gray: '#E9EDEF',
	orange: '#FFA113',
  textGray: '#A7AAC1',
	darkNavy: '#00063c',
  bgGray: '#F9FAFB',
	veryLightBlue: '#e5ecef',
	error: '#FF6352',
	cloudyBlue: '#b9bbcb',
}

const breakpoints = {
  xs: '480px',
	sm: '768px',
	md: '992px',
  lg: '1024px',
  xl: '1440px'
}

const font = {
  family: 'Inter, sans-serif',
  regular: 'normal',
	bolder: 'bold'
}

const height = {
	full: '100vh'
}

const theme = {
  colors,
  breakpoints,
  font,
	height
}

export default theme;
