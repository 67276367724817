import * as types from './types';

export const setRoute = route => {
  return {
    type: types.SET_ROUTE,
    payload: route,
  };
};

export const setCanRefresh = can => {
  return {
    type: types.SET_CAN_REFRESH,
    payload: can,
  };
};

export const setRouteLoading = isLoading => {
  return {
    type: types.SET_ROUTE_LOADING,
    payload: isLoading,
  }
}
