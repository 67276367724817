import React from 'react';
import {OverlayView} from '@react-google-maps/api';
import {MarkerWrapper, PointLabel, BusNumberFrom, BusNumberTo} from './styles';
import {ReactComponent as TransferIcon} from 'assets/icons/transfer_marker.svg';
import {useTranslation} from 'react-i18next';

const TransferMarker = ({ position, busFromNumber, busToNumber }) => {

  const {t} = useTranslation();

  return (
    <OverlayView 
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}>
      <MarkerWrapper>
        <TransferIcon />
        <PointLabel>{t('markers.transfer')}</PointLabel>
        <BusNumberFrom>{busFromNumber}</BusNumberFrom>
        <BusNumberTo>{busToNumber}</BusNumberTo>
      </MarkerWrapper>
    </OverlayView>
  );
};

export default TransferMarker;