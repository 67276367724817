import React from 'react';
import {
  Footer,
	FooterText,
} from 'styled/Layout';
import {useTranslation} from 'react-i18next';

const ScreenFooter = () => {

  const {t} = useTranslation();

  return (
    <Footer>
      <FooterText>{t('footer.text')}</FooterText>
    </Footer>
  );
};

export default ScreenFooter;
