import Axios from 'axios';
import {BASE_API_URL} from './config';
import {displayProperToast} from 'utils/toast';
import i18next from 'i18next';

const API = Axios.create({
  baseURL: BASE_API_URL,
  headers: {'Content-Type': 'application/json'},
});

API.interceptors.response.use(
  response => {
    return response.data;
  },
    error => {
    const res = error.response;
    if (res && res.status) {
      switch (res.status) {
        case 400:
          if (res.data && res.data.flashes) {
            displayProperToast(res.data.flashes);
            return {
              status: 400,
              flashes: res.data.flashes,
            }
          } else {
            displayProperToast({
              error: [i18next.t('api.unexpectedError')],
            });
            return {
              status: 400,
              flashes: i18next.t('api.unexpectedError'),
            }
          }
        case 422:
          if (res.data && res.data.data && res.data.data.form_error) {
            return res.data;
          } else {
            if (res.data && res.data.flashes) {
              return {
                status: 422,
                flashes: res.data.flashes,
              }
            } else {
              return {
                status: 422,
                flashes: i18next.t('api.unexpectedError'),
              }
            }
          }
        default:
          displayProperToast({
            error: [i18next.t('api.unexpectedError')],
          });
          return {
            flashes: i18next.t('api.unexpectedError'),
          }
      }
    }
  },
);

export default API;
