import styled, {keyframes} from 'styled-components';
import theme from 'config/theme';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${spin} 1s linear infinite;
  border: 3px solid #ddd;
  border-top: 3px solid ${theme.colors.apple};
  border-radius: 50%;
  height: 60px;
  width: 60px;
`;
