import styled, {css, keyframes} from 'styled-components';
import {rem, lighten} from 'polished';
import theme from 'config/theme';

const Form = styled.form`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 25px;
`;

const InputBar = styled.span`
	position: relative;
	display: block;
	max-width: 280px;
	@media (min-width: ${theme.breakpoints.lg}) {
		max-width: 220px;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		max-width: 280px;
	}
	@media (max-width: 375px) {
		max-width: 250px;
	}
	
	&:not(:first-child) {
		margin-top: 16px;
		@media (max-width: 568px) {
		margin-top: 8px;
		}
	}
`

const InputLabel = styled.span`
	position: absolute;
	top: 0;
	left: 10px;
	width: 100%;
	height: 100%;
	pointer-events: none;
	&:before {
		content: '';
		height: 2px;
		width: 0;
		top: 0;
		position: absolute;
		background-color: ${theme.colors.white};
		transition: 0.2s ease all;
		left: 0;
	}
`

const Label = styled.label`
	font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('12px')};
	color: ${theme.colors.darkNavy};
	position: absolute;
	pointer-events: none;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	text-align: left;
	line-height: 1;
	margin-left: 16px;
	padding-right: 3px;
	background-color: ${theme.colors.white};
	transition: 0.2s ease all;
`

const Input = styled.input`
	padding: 16px;
	width: 280px;
	height: 40px;
	border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.veryLightBlue};
	border-radius: 4px;
	font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('12px')};
	color: ${theme.colors.darkNavy};
	transition: border-color .2s ease-in;
	@media (min-width: ${theme.breakpoints.lg}) {
		width: 220px;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		width: 280px;
	}
	@media (max-width: 375px) {
		width: 250px;
	}
	
	&:focus {
		outline: none;
		border-color: ${theme.colors.apple};
	}
	
	&:focus ~ label,
 	&:valid ~ label {
		color: ${props => props.error ? theme.colors.error : theme.colors.cloudyBlue};
		font-size: ${rem('10px')};
		top: 0;
	}

	&:focus ~ ${InputLabel}:before,
	&:valid ~ ${InputLabel}:before {
    width: 33px;
  }

	& ~ label {
		color: ${props => props.error ? theme.colors.error : theme.colors.darkNavy};
	}
`

const PrimaryButtonIcon = styled.div`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%) translateX(-16px);
	transition: transform .3s ease, opacity .3s ease;
`

const PrimaryButtonStyles = css`
	position: relative;
	min-width: 280px;
	min-height: 40px;
	outline: none;
	padding: 12px 16px;
	border-radius: 4px;
	border: none;
	background-color: ${theme.colors.apple};
	text-decoration: none;
	text-align: left;
	overflow: hidden;
	cursor: pointer;
	@media (min-width: ${theme.breakpoints.lg}) {
		min-width: 220px;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		min-width: 280px;
	}
	@media (max-width: 375px) {
		min-width: 250px;
	}
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transform: scaleX(0);
		transform-origin: left;
		background-color: ${theme.colors.darkNavy};
		transition: transform .3s ease, opacity .3s ease;
	}
	
	&:hover, &:focus {
	
		&:before {
			opacity: 1;
			transform: scaleX(1);
		}
		
		${PrimaryButtonIcon} {
			transform: translateY(-50%) translateX(-12px);
			opacity: .6;
		}
	}
`

const PrimaryButton = styled.button`
	${PrimaryButtonStyles};

	max-width: 280px;
	margin: 0;

	&.loading {
		
		&:before {
			opacity: 1;
			transform: scaleX(1);
		}
	}
`

const PrimaryButtonText = styled.span`
	position: relative;
	font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('12px')};
	line-height: 1.17;
	color: ${theme.colors.white};
	text-align: left;
`

const LoginButtonStyles = css`
	${PrimaryButtonStyles};
	margin-top: 16px;
	max-height: 40px;
	max-width: 280px;
	@media (min-width: ${theme.breakpoints.lg}) {
		max-width: 220px;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		max-width: 280px;
	}
	@media (max-width: 568px) {
		margin-top: 8px;
	}
	@media (max-width: 375px) {
		max-width: 250px;
	}
`

const LoginButton = styled(PrimaryButton)`
	${LoginButtonStyles};
`

const spin = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
	transform: rotate(360deg);
	}
`

const ButtonLoader = styled.div`
	margin: 0 auto;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	border: 4px solid ${theme.colors.white};
	border-top-color: transparent;
	animation: ${spin} .9s ease infinite;
`

const ErrorMessage = styled.div`
	padding: 12px;
	width: 280px;
	background-color: ${props => props.error ? lighten(0.3, theme.colors.error) : lighten(0.15, theme.colors.apple)};
	color: ${props => props.error ? lighten(0.1, theme.colors.error) : theme.colors.white};
	border-radius: 4px;
	margin: 0 auto 15px;
	font-size: ${rem('12px')};
	min-height: 40px;
	text-align: center;
	pointer-events: none;
	transition: all .2s ease-in;
	
	@media (min-width: ${theme.breakpoints.lg}) {
		width: 220px;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		width: 280px;
	}
	@media (max-width: 375px) {
		width: 250px;
	}
`

export {
	Form,
	InputBar,
	InputLabel,
	Label,
	Input,
	LoginButtonStyles,
	LoginButton,
	PrimaryButtonIcon,
	PrimaryButtonText,
	ButtonLoader,
	ErrorMessage
}
