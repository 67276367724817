import {createGlobalStyle} from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    @media (max-width: ${theme.breakpoints.sm}) {
      font-size: 15px;
    }
  }

  body {
    font-family: ${theme.font.family};
    margin: 0;
    overflow-x: hidden;
  }

  .ISSToast {
    .Toastify__toast {
      font-family: ${theme.font.family};
      padding: 20px;
      font-size: ${rem('14px')};
      border-radius: 4px;
    }
    .Toastify__toast--error {
      background: ${theme.colors.error};
      color: ${theme.colors.white};
    }
    .Toastify__toast--success {
      background: ${theme.colors.apple};
      color: ${theme.colors.white};
    }
  }
`;
