import React from 'react';
import { Spinner } from './styles';

const PageLoader = () => {
  return (
    <Spinner />
  );
};

export default PageLoader;
