import styled, {keyframes} from 'styled-components';
import theme from 'config/theme';

const fadeAnimation = keyframes`
  0% {
    transform: scale(0.1);
		opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const pulseAnimation = keyframes`
  0% {
		transform: scale(0.1);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
`;

export const PulseWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 100;
  align-items: center;
  justify-content: center;
  transform: ${props => props.degree ? `translate(-50%, -50%) rotate(${props.degree}deg)` : `translate(-50%, -50%)`};
`;

export const Pulse = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${theme.colors.orange};
  opacity: 0.6;
  animation: ${pulseAnimation};
  animation-duration: 3s;
  animation-delay: 0.4s;
  animation-iteration-count: infinite;
`;

export const SmallCircle = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: ${theme.colors.orange};
  border-radius: 50%;
  animation-name: ${fadeAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;

export const CarIconWrapper = styled.div`
  position: absolute;
`;
